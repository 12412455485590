/* -------------------------------------------------------------------------- */
/*                   Technology Page Properties and Content                   */
/* -------------------------------------------------------------------------- */
export const TechnologyPageContent = {
    data2: {
        backgoundText1: "techno-",
        backgoundText2: "logy",
        NDBSectionTitle: "VECITO Model",
        paragraphUnderQuote1:
            "Our commitment to sustainability and innovation drives us to develop eco-friendly solutions that not only meet today's energy demands but also secure a cleaner future.",
        paragraphUnderQuote2:
            "Through our multifaceted approach, we aim to bridge the gap between renewable energy and digital commerce, creating a comprehensive ecosystem that empowers users and fosters global collaboration.",
        paragraphUnderQuote3:
            "We believe that the fusion of hydrogen energy, advanced battery systems, blockchain, and eCommerce will lead to a sustainable economic model.",
    },
    data2Mobile: {
        backgoundText1: "design",
        NDBSectionTitle: "VECITO Model",
        paragraphUnderQuote1:
            "Our commitment to sustainability and innovation drives us to develop eco-friendly solutions that not only meet today's energy demands but also secure a cleaner future.",
        paragraphUnderQuote2:
            "Through our multifaceted approach, we aim to bridge the gap between renewable energy and digital commerce, creating a comprehensive ecosystem that empowers users and fosters global collaboration.",
        paragraphUnderQuote3:
            "We believe that the fusion of hydrogen energy, advanced battery systems, blockchain, and eCommerce will lead to a sustainable economic model.",
    },
    data3Mobile: {
        backgoundText1: "design",
        backgoundText2: "&",
        NDBSectionTitle: "",
        paragraphUnderQuote1:
            "At the forefront of innovation, we are transforming the energy landscape with our pioneering technologies in hydrogen energy.",
        paragraphUnderQuote2:
            "Our cutting-edge hydrogen solutions aim to provide clean, sustainable energy alternatives that reduce carbon footprints and empower industries.",
        paragraphUnderQuote3:
            "Alongside hydrogen, we’re developing next-generation batteries that promise superior efficiency and longer lifespans, revolutionizing energy storage for various applications.",
        paragraphUnderQuote4:
            "Integrating blockchain technology, we enhance transparency and security in energy transactions, ensuring trust and accountability in every exchange.",
        paragraphUnderQuote5:
            "By leveraging blockchain, we create a robust platform for secure handling of digital assets, enabling greater accessibility and flexibility in the marketplace.",
    },
    data3: {
        backgoundText1: "design",
        backgoundText2: "&",
        NDBSectionTitle: "",
        paragraphUnderQuote1:
            "At the forefront of innovation, we are transforming the energy landscape with our pioneering technologies in hydrogen energy.",
        paragraphUnderQuote2:
            "Our cutting-edge hydrogen solutions aim to provide clean, sustainable energy alternatives that reduce carbon footprints and empower industries.",
        paragraphUnderQuote3:
            "Alongside hydrogen, we’re developing next-generation batteries that promise superior efficiency and longer lifespans, revolutionizing energy storage for various applications.",
        paragraphUnderQuote4:
            "Integrating blockchain technology, we enhance transparency and security in energy transactions, ensuring trust and accountability in every exchange.",
        paragraphUnderQuote5:
            "By leveraging blockchain, we create a robust platform for secure handling of digital assets, enabling greater accessibility and flexibility in the marketplace.",
    },
}
